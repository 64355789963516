.breadcrumbs{
    max-width: 1200px;
    margin: 5px auto;   
    display: flex;
    justify-content: end;
}

.breadcrumbs >*{
    display: inline-block;
    margin-right: 10px;
}

.breadcrumbs .crumb:after{
   content: ">";
   margin-left: 10px;
}

.breadcrumbs .crumb:last-child:after{
    display: none;
}

.breadcrumbs .crumb a{
    color: gray;
    padding: 0 5px 0 5px;
    border-radius: 5px;
}